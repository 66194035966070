import React, {  useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as SearchSvg } from '@ecp/common/src/assets/icon/icon__search.svg';

const TextSwiperItem = ({ item }) => {
  const { text } = item;

  return (
    <TextBox cursor={'text'} width={'429px'}>
      {text}
    </TextBox>
  );
};

TextSwiperItem.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string,
  }),
};

const GnbSearchPhrase = ({ searchPhraseList, searchPraseListIndex, handleChangeMode, handleClickSearch }) => {
  const currentPhrase = useMemo(() => {
    return searchPhraseList[searchPraseListIndex];
}, [searchPhraseList, searchPraseListIndex]);

  return (
    <Box>
      <FlexBox data-ds-label2={'g_search_bar'} onClick={handleChangeMode} width={'429px'} height={'20px'} cursor={'text'} >
        {searchPhraseList[searchPraseListIndex].text}
      </FlexBox>
      <SearchIcon
        data-ds-label2={'g_search_copy'}
        data-ds-contents={currentPhrase?.pcUrl}
        // onClick={() => handleClickSearch(currentPhrase)}
        onClick={async (e) => {
          e.preventDefault();
          await handleClickSearch(currentPhrase);
        }}
      />
    </Box>
  );
};

const Box = styled(FlexBoxSpaceBetween)`
  height: 50px;
  width: 500px;
  background-color: ${theme.color.background.white};
  border: 3px solid ${theme.color.primary};
  border-radius: 50px;
  padding: 15px 20px;
`;

const SearchIcon = styled(SearchSvg)`
  cursor: pointer;
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
`;

export default GnbSearchPhrase;

GnbSearchPhrase.propTypes = {
  searchPhraseList: PropTypes.array,
  searchPraseListIndex: PropTypes.number,
  handleChangeMode: PropTypes.func,
  handleClickSearch: PropTypes.func,
};
