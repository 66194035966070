import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useHover from '@ecp/common/src/hooks/useHover';
import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import { theme } from '@ecp/common/src/style/theme/default';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';

export const StyledIconButton = styled(ButtonUnstyled)`
  ${flexCenter};
  background-color: ${theme.color.background.white};
  cursor: pointer;
`;

const StyledIconButtonRoot = styled(StyledIconButton)`
  width: 40px;
  height: 40px;
  fill: ${theme.color.icon.default};
  border: 1px solid ${theme.color.line.background};

  &:hover {
    fill: ${theme.color.primary};
    border: 1px solid ${theme.color.primary};
  }
`;

export const IconButton = ({ icon: Icon, buttonProp, ...props }) => (
  <StyledIconButtonRoot {...buttonProp}>
    <Icon {...props} />
  </StyledIconButtonRoot>
);

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  icon: PropTypes.elementType,
  buttonProp: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export const StyledRoundIconButton = styled(StyledIconButton)`
  stroke: ${theme.color.line.emphasis};
  fill: ${theme.color.background.white};
`;

const StyledRoundIconButtonRoot = styled(StyledRoundIconButton).attrs(({ width = '50px', height = '50px', style }) => ({
  style: {
    ...style,
    width,
    height,
  },
}))`
  gap: 5px;
  min-width: 50px;
  min-height: 50px;
  border: 1px solid ${theme.color.secondary};
  border-radius: 4px;

  &:hover {
    fill: ${theme.color.primary};
    stroke: ${theme.color.primary};
    border: 1px solid ${theme.color.primary};
    color: ${theme.color.primary};
  }
`;

export const RoundIconButton = ({ icon: Icon, hoverIcon: HoverIcon, children, height, width, onClick, ...props }) => {
  const [hoverRef, isHovered] = useHover();
  return (
    <StyledRoundIconButtonRoot ref={hoverRef} height={height} width={width} onClick={onClick} aria-label={HoverIcon && isHovered ? "선물" : "위시"}>
      {HoverIcon && isHovered ? <HoverIcon {...props} /> : <Icon {...props} />}
      {children}
    </StyledRoundIconButtonRoot>
  );
};
RoundIconButton.displayName = 'RoundIconButton';

RoundIconButton.propTypes = {
  icon: PropTypes.elementType,
  hoverIcon: PropTypes.elementType,
  onClick: PropTypes.func,
  ...sizePropTypes,
};

const StyledEstimateViewButtonRoot = styled(StyledRoundIconButton).attrs(({ width = '50px', height = '50px', top = '', style }) => ({
    style: {
      ...style,
      width,
      height,
      top,
    },
  }))`
  gap: 5px;
  min-width: 50px;
  min-height: 50px;
  border: 1px solid ${theme.color.secondary};
  border-radius: 4px;
  position: relative;

  &:hover {
    fill: ${theme.color.primary};
    stroke: ${theme.color.primary};
    border: 1px solid ${theme.color.primary};
    color: ${theme.color.primary};
  }
`;

export const EstimateViewIconButton = ({ icon: Icon, children, height, width, top, onClick, ...props }) => {
  const [hoverRef] = useHover();
  return (
    <StyledEstimateViewButtonRoot ref={hoverRef} height={height} width={width} top={top} onClick={onClick} aria-label={"견적서 보기"}>
      <Icon {...props} />
      {children}
    </StyledEstimateViewButtonRoot>
  );
};
EstimateViewIconButton.displayName = 'EstimateViewIconButton';

EstimateViewIconButton.propTypes = {
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
  ...sizePropTypes,
};

