import { parse, isBefore, format } from 'date-fns';
export const isValidLength = (v, data, validateLength) => {
  return v.length <= validateLength[data.key];
};

export const hasSameChar = (text, len) => {
  const sameCharRegex = new RegExp(`(.)\\1{${len - 1}}`);
  return text.match(sameCharRegex);
};

export const hasOverlapKeyboard = (text, len) => {
  const keyboardLayout = [
    '1234567890-=',
    'qwertyuiop[]\\',
    "asdfghjkl;'",
    'zxcvbnm,./',
    '!@#$%^&*()_+',
    'QWERTYUIOP{}|',
    'ASDFGHJKL:"',
    'ZXCVBNM<>?',
  ];

  return keyboardLayout.some((layout) => hasOverlapText(layout, text, len));
};

export const hasOverlapText = (a, b, len) => {
  if (b.length < len) return false;
  const tmpB = b.substring(0, len);

  if (a.indexOf(tmpB) >= 0) {
    return true;
  }
  if (b.length === len) return false;

  return hasOverlapText(a, b.substring(1, b.length), len);
};

export const hasRequiredChars = (text) => {
  const requiredCharRegex = /(?=.*[\d])(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()]){8,15}/;
  return requiredCharRegex.test(text);
};

export const isValidLengthMinMax = (text, min, max) => {
  const lengthCheckRegex = new RegExp('^(.|\\n){' + min + ',' + max + '}$');
  return lengthCheckRegex.test(text);
};

export const isValidContents = (contents, min = 1, max = 20) => {
  const alphabetKoreanNumericCheckRegex = /[ㄱ-ㅎ가-힣a-zA-Z0-9]/;

  return isValidLengthMinMax(contents, min, max) && alphabetKoreanNumericCheckRegex.test(contents);
};

export const isValidPhoneOnlySite = (phoneNo) => {
  const regPhone = /^[0-9]{0,3}-?([0-9]{3,4})-([0-9]{4})$/;
  return regPhone.test(phoneNo);
};

export const isValidPhone = (phoneNo) => {
  const regPhone = /^[0-9]{2,3}-([0-9]{3,4})-([0-9]{4})$/;
  return regPhone.test(phoneNo);
};

export const isValidPhoneNoDash = (phoneNo) => {
  const regPhone = /^[0-9]{2,3}([0-9]{7,8})$/;
  return regPhone.test(phoneNo);
};

export const isValidCellPhone = (phoneNo) => {
  const regPhone = /^01([016789])-([0-9]{3,4})-([0-9]{4})$/;
  return regPhone.test(phoneNo);
};

export const isValidCellNoDash = (phoneNo) => {
  const regPhone = /^01([016789])([0-9]{7,8})$/;
  return regPhone.test(phoneNo);
};

export const isValidCellPhoneWithoutDash = (phoneNo) => {
  const regPhone = /^01([016789])([0-9]{3,4})([0-9]{4})$/;
  return regPhone.test(phoneNo);
};

export const isValidTimeFormat = (data) => {
  const timeFormat = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return timeFormat.test(data);
};

export const isValidTimeRange = (startTime, endTime) => {
  const start = parse(startTime, 'HH:mm', new Date());
  const end = parse(endTime, 'HH:mm', new Date());
  return isBefore(start, end);
};

export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*/=?^_`{|}~-]+@(([a-zA-Z\-0-9]+\.){1,4}[a-zA-Z]{2,4})$/;
  return emailRegex.test(email);
};

export const isValidEmailDomain = (emailDomain) => {
  const emailDomainRegex = /^(([a-zA-Z\-0-9]+\.){1,4}[a-zA-Z]{2,4})$/;
  return emailDomainRegex.test(emailDomain);
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

export const validateId = (id) => {
  if (!id) {
    return '아이디를 입력해주세요.';
  }
  return '';
};

export const validatePassword = (password) => {
  if (!password) {
    return '비밀번호를 입력해주세요.';
  }
  return '';
};

export const validateNewPassword = (newPassword, reNewPassword, userId, minLength = 8, maxLength = 15) => {
  if (!newPassword) {
    return '신규 비밀번호를 입력해주세요.';
  }
  if (!reNewPassword) {
    return '신규 비밀번호를\n한 번 더 입력해 주세요.';
  }
  if (!isValidLengthMinMax(newPassword, minLength, maxLength)) {
    return `비밀번호는 최소 ${minLength}자~최대 ${maxLength}자 이내로\n입력 바랍니다.`;
  }
  if (!hasRequiredChars(newPassword)) {
    return `비밀번호는 영문 대문자, 영문 소문자, 특수기호, 숫자를 3가지 이상 조합하여 사용 가능합니다.`;
  }
  if (hasOverlapText(userId, newPassword, 3)) {
    return `비밀번호로 아이디와 연속된 세 자릿수 이상은\n사용할 수 없습니다.`;
  }
  if (hasSameChar(newPassword, 3)) {
    return '비밀번호로 같은 문자나 숫자를 3번 이상\n중복할 수 없습니다.';
  }
  if (hasOverlapKeyboard(newPassword, 4)) {
    return '비밀번호로 연속된 키보드 문자열 조합을\n사용할 수 없습니다.';
  }
  if (newPassword.includes(' ')) {
    return '비밀번호에 공백은 사용할 수 없습니다.';
  }
  if (newPassword !== reNewPassword) {
    return '비밀번호가 일치하지 않습니다.\n다시 입력해주세요.';
  }
  return '';
};

export const validateNewId = (id, minLength = 4, maxLength = 20, isAssociate = false) => {
  if (!id) {
    return '아이디를 입력해주세요.';
  }
  if (!isValidLengthMinMax(id, minLength, maxLength)) {
    return `아이디는 최소 ${minLength}자 ~ 최대 ${maxLength}자 이내로 입력바랍니다`;
  }

  const idRegex = isAssociate ? /^[a-zA-Z0-9.]+$/ : /^[a-zA-Z0-9]+$/;
  if (!idRegex.test(id)) {
    return '아이디는 영문대문자, 영문소문자, 숫자만\n사용 가능합니다.';
  }

  const onlySpecialCharsRegex = isAssociate ? /^[-_!]+$/ : /^[-_.!]+$/;
  if (onlySpecialCharsRegex.test(id)) {
    return '최소 1개의 영문자/숫자를 포함해주세요.';
  }

  return '';
};

export const replaceValidId = (id) => {
  const validIdInput = /[^a-zA-Z0-9]/gi;
  return id.replace(validIdInput, '');
};

export const validatePhoneNumber = ({
  phoneNumber,
  minLength = 10,
  maxLength = 11,
  label = '휴대전화번호',
  type = 'CELL',
}) => {
  if (!phoneNumber) {
    return `${label}를 정확히 입력해주세요.`;
  }
  if (!isValidLengthMinMax(phoneNumber, minLength, maxLength)) {
    return `${label}를 정확히 입력해주세요.`;
  }
  if (type === 'CELL') {
    if (!/^(01[016789])[0-9]{3,4}[0-9]{4}$/.test(phoneNumber)) {
      return `${label}를 정확히 입력해주세요.`;
    }
  }
};

export const validateEmail = (emailId, emailDomain, minLength = 1, maxLength = 100) => {
  if (!emailId) {
    return '이메일을 정확히 입력해주세요.';
  }
  if (!emailDomain) {
    return '이메일을 정확히 입력해주세요.';
  }
  if (!isValidLengthMinMax(emailId, minLength, maxLength)) {
    return '이메일을 정확히 입력해주세요.';
  }
  if (!isValidEmailDomain(emailDomain)) {
    return '이메일을 정확히 입력해주세요.';
  }
  if (!isValidEmail(`${emailId}@${emailDomain}`)) {
    return '이메일을 정확히 입력해주세요.';
  }
  return '';
};

export const validateBirthday = (birthday, minLength=8, maxLength=8, minAge = 14) => {

  if(!birthday) {
    return '생년월일을 입력해 주세요.';
  }

  const birthDate = (birthday instanceof Date && birthday != 'Invalid Date')
    ? format(birthday, "yyyyMMdd")
    : birthday;

  if (!isValidLengthMinMax(birthDate, minLength, maxLength)) {
    return `생년월일을 ${maxLength}자리로 입력해 주세요.`;
  }

  if(!(birthday instanceof Date) || birthday == 'Invalid Date') {
    return '생년월일을 다시 입력해 주세요.';
  }

  const birthYear = birthday.getFullYear();
  const birthMonth = birthday.getMonth();
  const birthDay = birthday.getDate();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  let age = currentYear - birthYear;

  if (currentMonth < birthMonth) {
    age--;
  }
  else if (currentMonth === birthMonth && currentDay < birthDay) {
    age--;
  }

  if(age < minAge) {
    return '만 14세 이상 가입 가능합니다.\n생년월일을 확인해 주세요.'
  }

  return ''

};
