import { atom } from 'recoil';

export const systemInformationState = atom({
  key: 'systemInformationState',
  default: {
    clientName: '',
    clientRepresentativeName: '',
    clientRepresentativeTel01: '',
    clientRepresentativeTel02: '',
    clientRepresentativeTel03: '',
    couponUseYn: '',
    isPossibleKnoxLogin: '',
    isMultimall: true,
    logoImgBtypeUseYn: '',
    mobileGnbImgFilePath: '',
    mobileLoginImgFilePath: '',
    mobileFooterImgFilePath: '',
    pcFooterImgFilePath: '',
    pcGnbImgFilePath: '',
    pcLoginImgFilePath: '',
    pcLoginPhrase: '',
    mobileLoginPhrase01: '',
    mobileLoginPhrase02: '',
    loginQuestUseYn: '',
    memberAddInfoInptYn: '',
    memberEntryBtnYn: '',
    memberEntryMeanCode: '',
    memberGendInptYn: '',
    memberGradeUseYn: '',
    pointUseYn: '',
    possibleKnoxLogin: '',
    promoBannerBtypeUseYn: '',
    pcPromoBannerImgFilePath: '',
    savingsUseYn: '',
    otoQuestUseYn: '',
    siteDescription: '',
    siteName: '',
    useYn: '',
    customerCenterOpStartHh: '',
    customerCenterOpEndHh: '',
    customerCenterLchStartHh: '',
    customerCenterLchEndHh: '',
    siteTypeCode: '',
    mktingUseYn: '',
    pntExpsNm: '',
    cartUseYn: '',
    priceExpsUseYn: '',
    couponMenuExpsYn: '',
    siteNumber: '',
  },
});
