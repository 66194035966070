import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';

const BenefitChipRoot = styled(FlexBox)`
  padding: 0 4px;
  height: 19px;
  font-size: 12px;
  border: 1px solid ${theme.color.sub_darkgray};
  ${({ item }) => {
    if (item === 'NEW' || item === '대량주문') {
      return {
        color: `${theme.color.background.white}`,
        backgroundColor: `${theme.color.sub_darkgray}`,
      };
    } else {
      return {
        color: `${theme.color.sub_darkgray}`,
      };
    }
  }};
`;

const GoodBenefitChip = ({ item }) => {
  return <BenefitChipRoot item={item}>{item}</BenefitChipRoot>;
};

GoodBenefitChip.propTypes = {
  item: PropTypes.string,
};

export default GoodBenefitChip;
