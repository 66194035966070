import React, { Suspense, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE as useRecoilRefresher, useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getInitialQueryString, SEARCH_TAB_TYPE } from '@ecp/common/src/utils/good/goodSearchUtil';
import useSearchHistory from '@ecp/common/src/hooks/useSearchHistory';
import { zIndex } from '@ecp/common/src/style/constant';
import theme from '@ecp/common/src/style/theme/default';
import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import Paper from '@ecp/common/src/components/paper/Paper';
import TabGroup from '@ecp/common/src/components/tab/TabGroup';
import { TabPanel } from '@ecp/common/src/components/tab/TabPanel';
import { radioClassNames } from '@ecp/common/src/components/unstyled/input/RadioUnstyled';

import { userInfoState } from '@fo-recoil/common/user/atom';
import { withTrendKeywords } from '@fo-recoil/search/selector';
import SuspenseSpinner from '@fo-components/common/SuspenseSpinner';
import RecommendKeyword from '@fo-pages/home/gnb/search/RecommendKeyword';
import SearchKeywordHistoryTab from '@fo-pages/home/gnb/search/SearchKeywordHistoryTab';
import SearchAutoComplete from '@fo-pages/home/gnb/search/SearchAutoComplete';

const RealtimeSearchKeywordTab = React.lazy(() => import('@fo-pages/home/gnb/search/RealtimeSearchKeywordTab'));

const tabItems = [
  { tabName: '최근 검색어', width: '100px', 'data-ds-label2': 'g_rece_search' },
  { tabName: '실시간 검색어', width: '120px', 'data-ds-label2': 'g_real_time_seach' },
];
const GnbSearchBoardTab = ({
  currentTab,
  handleChangeTab,
  handleDelete,
  handleDeleteAll,
  handleHistoryOnOff,
  keywordList,
  keywordHistoryOn,
  onSearch,
}) => {
  const tabIndex = useMemo(() => {
    return currentTab === SEARCH_TAB_TYPE.HISTORY ? '0' : '1';
  }, [currentTab]);

  const onChange = useCallback(
    (e) => {
      const updatedTab = e?.target?.value === '0' ? SEARCH_TAB_TYPE.HISTORY : SEARCH_TAB_TYPE.REAL_TIME;
      handleChangeTab(updatedTab);
    },
    [handleChangeTab]
  );

  return (
    <TabGroup
      width={'440px'}
      height={'26px'}
      tabItems={tabItems}
      onChange={onChange}
      tabSlot={TextButtonStyle}
      value={tabIndex}
    >
      <TabPanel>
        <SearchKeywordHistoryTab
          isOn={keywordHistoryOn}
          onSearch={onSearch}
          keywordList={keywordList}
          handleDelete={handleDelete}
          handleDeleteAll={handleDeleteAll}
          handleHistoryOnOff={handleHistoryOnOff}
        />
      </TabPanel>
      <TabPanel undead>
        <Suspense
          fallback={
            <SuspenseSpinner width={'440px'} height={'409px'} spinnerProps={{ width: '60px', height: '60px' }} />
          }
        >
          <RealtimeSearchKeywordTab onSearch={onSearch} />
        </Suspense>
      </TabPanel>
    </TabGroup>
  );
};

GnbSearchBoardTab.propTypes = {
  currentTab: PropTypes.string,
  keywordList: PropTypes.array,
  keywordHistoryOn: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleDeleteAll: PropTypes.func,
  handleHistoryOnOff: PropTypes.func,
  handleChangeTab: PropTypes.func,
  onSearch: PropTypes.func,
};

const GnbSearchBoard = ({
  keywordList,
  recommendKeywordList,
  keyword,
  keywordHistoryOn,
  keywordHistoryList,
  currentTab,
  onClose,
  setKeywordHistoryList,
  handleDeleteKeywordHistory,
  handleDeleteAllKeywordHistory,
  handleHistoryOnOff,
  handleChangeTab,
}) => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userInfoState);
  const { addSearchKeyword } = useSearchHistory(userInfo?.userId);
  const refreshTrendKeyword = useRecoilRefresher(withTrendKeywords);

  useEffect(() => {
    refreshTrendKeyword();
  }, [refreshTrendKeyword]);
  const handleSearch = (keyword) => {
    onClose();

    if (keywordHistoryOn) {
      const updatedList = addSearchKeyword(keyword?.trim?.());
      setKeywordHistoryList(updatedList);
    }

    navigate({
      pathname: '/search',
      search: getInitialQueryString({ searchTerm: keyword?.trim?.(), countPerPage: 60 }),
    });
  };

  return (
    <SearchWrapper outline elevation round padding={'29px 29px 19px 29px'}>
      <Paper width={'440px'} closable onClose={onClose}>
        {keyword ? (
          <SearchAutoComplete keyword={keyword} keywordList={keywordList} onSearch={handleSearch} />
        ) : (
          <>
            <GnbSearchBoardTab
              currentTab={currentTab}
              keywordHistoryOn={keywordHistoryOn}
              onSearch={handleSearch}
              keywordList={keywordHistoryList}
              handleDelete={handleDeleteKeywordHistory}
              handleDeleteAll={handleDeleteAllKeywordHistory}
              handleHistoryOnOff={handleHistoryOnOff}
              handleChangeTab={handleChangeTab}
              recommendKeywords={recommendKeywordList}
            />
            {!!recommendKeywordList?.length && (
              <RecommendKeyword
                recommendKeywords={recommendKeywordList}
                onSearch={handleSearch} />
            )}
          </>
        )}
      </Paper>
    </SearchWrapper>
  );
};

export default GnbSearchBoard;

GnbSearchBoard.propTypes = {
  keywordList: PropTypes.array,
  recommendKeywordList: PropTypes.array,
  keyword: PropTypes.string,
  keywordHistoryOn: PropTypes.bool,
  keywordHistoryList: PropTypes.array,
  currentTab: PropTypes.string,
  onClose: PropTypes.func,
  setKeywordHistoryList: PropTypes.func,
  handleSearch: PropTypes.func,
  handleDeleteKeywordHistory: PropTypes.func,
  handleDeleteAllKeywordHistory: PropTypes.func,
  handleHistoryOnOff: PropTypes.func,
  handleChangeTab: PropTypes.func,
};

const SearchWrapper = styled(Paper)`
  z-index: ${zIndex.contextMenu};
  ${flexCenter};
  width: 500px;
  align-items: flex-start;
  background: ${theme.color.background.white};
`;

const dividerCss = css`
  padding-left: 0;
  &:after {
    position: absolute;
    content: '';
    top: 5px;
    bottom: 5px;
    right: 0;
    height: 16px;
    width: 1px;
    background-color: ${theme.color.line.background};
  }
`;

const TextButtonStyle = styled(FlexBox)`
  font-size: ${theme.font.size.title};
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.text.disabled};
  padding-left: 14px;
  cursor: pointer;
  position: relative;

  &.${radioClassNames.checked} {
    color: ${theme.color.text.basic};
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 2px;
  }

  &:hover {
    color: ${theme.color.text.selected};
  }

  ${({ firstChild }) => firstChild && dividerCss}
`;
